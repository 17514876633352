// extracted by mini-css-extract-plugin
export var ourMission = "about-module--ourMission--1X7_B";
export var getTouch = "about-module--getTouch--2pSJp";
export var main = "about-module--main--1ouAF";
export var mainRaised = "about-module--mainRaised--3BGDi";
export var paddingMain = "about-module--paddingMain--22N2b";
export var container = "about-module--container--3dvfR";
export var mobileFlex = "about-module--mobileFlex--187on";
export var contactUs = "about-module--contactUs--2IE4Z";
export var statement = "about-module--statement--2TmMY";
export var space150 = "about-module--space150--12EKR";
export var space90 = "about-module--space90--1BZ9F";
export var space45 = "about-module--space45--2hG0i";
export var header = "about-module--header--143uk";
export var mobileSpace = "about-module--mobileSpace--smHmx";
export var blueBanner = "about-module--blueBanner--1dx2c";