import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import { ImageQueryQuery } from "../../graphql-types"

const UWayLogo: React.FC = () => {
    const data: ImageQueryQuery = useStaticQuery(graphql`query uwayQuery {
  placeholderImage: file(relativePath: {eq: "uwaylogo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <GatsbyImage image={data.placeholderImage?.childImageSharp?.gatsbyImageData} alt="United Way Logo"/>
  )
}

export default UWayLogo