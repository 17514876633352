import React from "react"
import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import UWayLogo from "../components/uwaylogo"
import SEO from "../components/seo"
import { Link } from "gatsby"

const styles = require('../styles/about.module.css')
const classNames = require('classname')

const AboutPage: React.FC = () => (
    <Layout>
        <SEO title="About"/>
        <div className={styles.container}>
            
            <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
            <div className={styles.blueBanner}>
                <div className={styles.space150}></div>
                    <div className={styles.header}>
                    <h1 className={styles.statement}><b>When we say Park to Give, we mean it</b></h1>
                    </div>
                    <div className={styles.space150}></div>
                    </div>
                <div className={styles.mobileFlex}>
                    <div className={styles.mobileSpace}></div>
                        
                    <div className={styles.space90}></div>
                    <div className={styles.ourMission}>
                        <h1>Our mission</h1>
                        <p>We want to create a new model for generating economic and social good in our community – and also fill a practical need. </p>
                        <ul/>
                        <p>Why not start with parking?</p>
                        <div className={styles.space45}></div>
                        <h1>Our values</h1>
                        <p><b>Park to Give</b> is committed to our business model. Our partner, the United Way of the Midlands, will receive all net proceeds from the amount you pay to park.
                         All expenses, including credit card payment and transaction fees, will be deducted from the parking space owner’s portion of the proceeds. </p>
                    <div style={{ width: "20%", margin: "40px 0px", minWidth: "200px" }}>
                        <a href="https://www.uway.org/">
                        <UWayLogo/>
                        </a>
                    </div>
                    </div>
                    <div className={styles.getTouch}>
                        <h1>Get in touch</h1>
                        <p>We understand you might have some questions about <b>Park to Give</b>, and we're here to answer them. Our team is committed to being
                        completely transparent about our company and our mission.</p>
                        <ul/>
                        <Link to="/contact/"><p className={styles.contactUs}>Contact us</p></Link>
                    </div>
                    
                    <div style={{width:"70%", height:"10px"}}></div>
                    <div style={{width: "100%"}}>
                        <Subscribe/>
                    </div>
                    
                </div>
            </div>
        </div>


       
        
    </Layout>
)

export default AboutPage